$code-green: #b5f4a5;
$code-yellow: #ffe484;
$code-purple: #d9a9ff;
$code-red: #ff8383;
$code-blue: #93ddfd;
$code-white: #ffffff;
$code-gray: #a0aec0;

pre.highlight,
.highlight pre {
  max-height: 30rem;
  margin: 1.5rem 0;
  overflow: auto;
  font-size: $code-font-size;
  background: $dark;
  border-radius: $border-radius;
  color: #ffffff;
  @include scrollbar(true);
}

.highlight {
  .c, .c1 { color: $code-gray; }
  .na, .nx, .nl, .language-css & .na, .language-scss & .na { color: $code-yellow; }
  .s, .dl, .s1, .s2, .mh { color: $code-green; }
  .mi, .language-js .nb, .nc, .nd, .nt { color: $code-blue; }
  .language-html .nt, .nb { color: $code-red; }
  .k, .kd, .nv, .n { color: $code-purple; }
}
