/*!
 * Tabler (v1.0.0-alpha.5)
 * Copyright 2018-2020 The Tabler Authors
 * Copyright 2018-2020 codecalm
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */
/**
Dark mode
 */
pre.highlight,
.highlight pre {
  max-height: 30rem;
  margin: 1.5rem 0;
  overflow: auto;
  font-size: 0.625rem;
  background: #354052;
  border-radius: 3px;
  color: #ffffff; }
  pre.highlight::-webkit-scrollbar,
  .highlight pre::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    -webkit-transition: .3s background;
    transition: .3s background; }
  pre.highlight::-webkit-scrollbar-thumb,
  .highlight pre::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: transparent; }
  pre.highlight::-webkit-scrollbar-corner,
  .highlight pre::-webkit-scrollbar-corner {
    background: transparent; }
  pre.highlight:hover::-webkit-scrollbar-thumb,
  .highlight pre:hover::-webkit-scrollbar-thumb {
    background: #cbcfd6;
    background: #5d6675; }

.highlight .c, .highlight .c1 {
  color: #a0aec0; }

.highlight .na, .highlight .nx, .highlight .nl, .language-css .highlight .na, .language-scss .highlight .na {
  color: #ffe484; }

.highlight .s, .highlight .dl, .highlight .s1, .highlight .s2, .highlight .mh {
  color: #b5f4a5; }

.highlight .mi, .highlight .language-js .nb, .highlight .nc, .highlight .nd, .highlight .nt {
  color: #93ddfd; }

.highlight .language-html .nt, .highlight .nb {
  color: #ff8383; }

.highlight .k, .highlight .kd, .highlight .nv, .highlight .n {
  color: #d9a9ff; }

.example {
  padding: 2rem;
  margin: 2rem 0;
  border: 1px solid rgba(110, 117, 130, 0.2);
  border-radius: 3px 3px 0 0;
  position: relative;
  min-height: 12rem;
  display: flex;
  align-items: center;
  overflow-x: auto; }

.example-centered {
  justify-content: center; }
  .example-centered .example-content {
    flex: 0 auto; }

.example-content {
  font-size: 0.875rem;
  flex: 1;
  max-width: 100%; }

.example-bg {
  background: #f5f7fb; }

.example-code {
  margin: 2rem 0;
  border-top: none; }
  .example-code pre {
    margin: 0;
    border-radius: 0 0 3px 3px; }
  .example + .example-code {
    margin-top: -2rem; }

.example-column {
  margin: 0 auto; }
  .example-column > .card:last-of-type {
    margin-bottom: 0; }

.example-column-1 {
  max-width: 20rem; }

.example-column-2 {
  max-width: 40rem; }

.example-modal-backdrop {
  background: #354052;
  opacity: 0.24;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 2px 2px 0 0; }

@media not print {
  .theme-dark .example-code {
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-top: none; } }

@media not print and (prefers-color-scheme: dark) {
  .theme-dark-auto .example-code {
    border: 1px solid rgba(110, 117, 130, 0.2);
    border-top: none; } }

.card-sponsor {
  background: #dbe7f6 no-repeat center/100% 100%;
  border-color: #548ed2;
  min-height: 316px; }

body.no-transitions * {
  transition: none !important; }

.toc-entry:before {
  content: '- '; }

.toc-entry ul {
  list-style: none;
  padding-left: 1rem; }

.toc-entry a {
  color: #6e7582; }

.dropdown-menu-demo {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 1rem; }
/*# sourceMappingURL=demo.css.map */