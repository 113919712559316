/*!
 * Tabler (v1.0.0-alpha.5)
 * Copyright 2018-2020 The Tabler Authors
 * Copyright 2018-2020 codecalm
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */

@import "config";
@import "demo/highlight";
@import "demo/examples";


.card-sponsor {
  background: #dbe7f6 no-repeat center/100% 100%;
  border-color: #548ed2;
  min-height: 316px;
}

body.no-transitions * {
  transition: none !important;
}

.toc-entry {
  &:before {
    content: '- '
  }

  ul {
    list-style: none;
    padding-left: 1rem;
  }

  a {
    color: $text-muted;
  }
}

.dropdown-menu-demo {
  display: inline-block;
  width: 100%;
  position: relative;
  top: 0;
  margin-bottom: 1rem;
}